import React, { Fragment } from "react";
import { PUBFORMATS } from "../pub/formats";
import Categorie from "./categorie";
import Publication from "./publication";
import Recette from "./recette";
import Episode from "./episode";
import Emission from "./emission";
import EpisodeRecent from "./contenuRecent/episodeRecent";
import RecetteRecent from "./contenuRecent/recetteRecent";
import type { Recette as RecetteType } from "@/types/recette";
import type { Emission as EmissionType, Episode as EpisodeType } from "@/types/emissions";
import { Categorie as CategorieType } from "@/types/categorie";
import { Publication as PublicationType } from "@/types/publication";
import { Contenu } from "@/types/contenu";

import { TypeEntite, TypeEntiteExtended, TypeEntiteFrontend } from "@/types/enum";

import Pub from "@/ui/pub";
import { VariantValues } from "./cartes.constants";

type CarteProps = {
    className?: string;
    contenu: Contenu;
    isContenuRecent?: boolean;
    variant?: VariantValues;
};

const Carte = ({
    className = "",
    contenu,
    isContenuRecent = false,
    variant = "collection",
}: CarteProps) => {
    const { type, id } = contenu || {};

    return (
        <Fragment key={id}>
            {isContenuRecent
                ? (() => {
                      switch (type) {
                          case TypeEntite.Episode:
                              return <EpisodeRecent episode={contenu as EpisodeType} />;
                          case TypeEntite.Recette:
                              return <RecetteRecent recette={contenu as RecetteType} />;
                          default:
                              console.error(
                                  `Type ${type} n'est pas supporté par Carte contenu récent`,
                              );
                              return null;
                      }
                  })()
                : (() => {
                      switch (type as TypeEntiteExtended) {
                          case TypeEntite.Categorie:
                              const catContenu = contenu as CategorieType;
                              return <Categorie categorie={catContenu} />;
                          case TypeEntite.Episode:
                              const epContenu = contenu as EpisodeType;
                              return <Episode episode={epContenu} variant={variant} />;
                          case TypeEntite.Emission:
                              const emContenu = contenu as EmissionType;
                              return <Emission emission={emContenu} />;
                          case TypeEntite.Publication:
                              const pubContenu = contenu as PublicationType;
                              return (
                                  <Publication
                                      className={className}
                                      publication={pubContenu}
                                      variant={variant}
                                  />
                              );
                          case TypeEntiteFrontend.Publicite:
                              return (
                                  <Pub className={className} format={PUBFORMATS.box} />
                              );
                          case TypeEntite.Recette:
                              return (
                                  <Recette className={className} recette={contenu as RecetteType} />
                              );
                          default:
                              console.error(`Type ${type} n'est pas supporté par Carte`);
                              return null;
                      }
                  })()}
        </Fragment>
    );
};

export default Carte;

import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { path } from "@/utils/path";

import Image from "@/ui/image";

import styles from "../uiContenuRecent.module.scss";
import { TYPE_GABARIT, LABEL_TAGINTO } from "@/utils/enums";

import PlayIconInTo from "@/ui/playIconInTo";
import TagInTo from "@/ui/tagInTo";
import { Image as ImageType } from "@/types/image";
import { Source } from "@/types/contenu";

type PropTypes = {
    className?: string;
    id: number | string;
    images?: ImageType[];
    isMediaDisponible?: boolean;
    sendLinkToVideo?: boolean;
    slug?: string;
    source?: Source;
    titre: string;
    type?: string;
};

const UiRecette = ({
    className = "",
    id,
    images,
    isMediaDisponible = false,
    sendLinkToVideo = false,
    slug,
    source,
    titre,
    type,
}: PropTypes) => {
    const url = { pathname: path.fill(path.RECETTE, { type: "recette", id, slug }), hash: "" };
    if (isMediaDisponible && sendLinkToVideo) {
        url.hash = "#zone-video";
    }

    const handleClick = () => {
        location.hash = "#zone-video";
    };

    const emission = source?.emission;

    return (
        <div
            data-component="UiRecette"
            className={classNames(styles.me, {
                [className]: className,
            })}
            key={id}
            data-type={type}
        >
            <TagInTo label={LABEL_TAGINTO.RECETTE} />
            <div className={styles.imgZone}>
                {url && (
                    <Link href={url} className={styles.imgLink} onClick={handleClick}>
                        {isMediaDisponible && <PlayIconInTo />}
                        {images && (
                            <Image
                                alt={titre}
                                className={styles.img}
                                gabarits={[TYPE_GABARIT.FORMAT_16X9]}
                                images={images}
                                lazyLoadImage={false}
                            />
                        )}
                    </Link>
                )}
            </div>
            <div className={styles.titleZone}>
                {emission && url && (
                    <Link href={url}>
                        <Image
                            alt={emission.nom}
                            ariaLabel={emission.nom}
                            className={styles.logo}
                            gabarits={[TYPE_GABARIT.FORMAT_LOGO]}
                            images={emission.images || []}
                            lazyLoadImage={false}
                        />
                    </Link>
                )}
                {titre && (
                    <h3 className={styles.titleName}>
                        <Link href={url}>{titre}</Link>
                    </h3>
                )}
            </div>
        </div>
    );
};

export default UiRecette;
